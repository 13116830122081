
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import MasterLayout from '@/components/MasterLayout.vue';
@Component({
  components: { MasterLayout }
})
export default class extends Vue {
  $refs!: {
    defaultActions: HTMLFormElement;
  };
  /* Properties */
  /* Store Actions */
  /* Watchers */
  /* Data */
  /* Utility Functions */
  /* Loaders */
  /* Mounted */
  /* Created */
  /* Emmited Functions */
}
