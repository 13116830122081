
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import DefaultLayout from '@/components/DefaultLayout.vue';
import NonLoggedInLayout from '@/components/NonLoggedInLayout.vue';
import { ValidationHelper } from '@/helpers/ValidationHelper';
@Component({
  components: { DefaultLayout, NonLoggedInLayout }
})
export default class extends Vue {
  /* Properties */
  @Prop() shouldForceNonLoggedInLayout!: boolean;
  @Prop({ default: 0 }) margin!: number;
  /* Store Actions */
  /* Watchers */
  /* Data */
  shouldUseNonLoggedInLayout: boolean = true;
  /* Utility Functions */
  /* Loaders */
  /* Mounted */
  mounted() {
    this.shouldUseNonLoggedInLayout =
      this.shouldForceNonLoggedInLayout || !ValidationHelper.isUserAuthenticated();
  }
  /* Created */
  /* Emmited Functions */
}
